<template>
  <div class="home">
    <div class="header">
      <!-- 导航栏 -->
      <!-- <div class="header-top">
        <div class="header-top-left" @click="back">
          <img src="../assets/math/logo-b.png" />
        </div>
        <div class="header-top-right">
          <div class="header-top-right-recruit text">
            {{ tabbar[lang][0] }}
          </div>
          <div class="line"></div>
          <el-dropdown @command="changeLang" placement="bottom" trigger="click">
            <div class="el-dropdown-link text">
              {{ tabbar[lang][1] }}
              <img src="../assets/math/down-arrow.png" />
            </div>
            <el-dropdown-menu slot="dropdown" class="dropdown-menu">
              <el-dropdown-item command="english" class="dropdown-menu-item"
                >EN</el-dropdown-item
              >
              <el-dropdown-item command="chinese" class="dropdown-menu-item"
                >中文</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div> -->
      <div class="header-top"></div>
      <div class="header-bottom">
        <div v-for="(item, index) in recruits" :key="index">
          <div class="header-bottom-title">
            {{ item[lang][0] }}
          </div>
          <div class="header-bottom-desc" v-html="item[lang][1]"></div>
          <div class="header-bottom-contact" v-html="item[lang][2]"></div>
        </div>
      </div>
    </div>

    <!-- 底部区域 -->
    <div class="footer">
      <div class="footer-bottom">
        <div class="footer-bottom-logo">
          <img
            v-if="lang === 'english'"
            class="logo1"
            src="../assets/math/logoxtal.png"
          />
          <img v-else class="logo1" src="../assets/math/logoxtal2.png" />
        </div>
        <div v-if="lang === 'english'" class="footer-bottom-text">
          To empower life-long growth with love and technology
          <br />
          MathGPT ©2023-2026
        </div>
        <div v-else class="footer-bottom-text">
          Copyright © 2023 MathGPT 北京世纪好未来教育科技有限公司版权所有
          <br />
          <span @click="toBeian"> 京ICP备13017119号-13 </span>
          ｜
          <span @click="toBeian2"> 京公网安备11010802042225号 </span>
        </div>
      </div>
    </div>

    <!-- 吸顶 -->
    <div v-show="true" class="xiding">
      <div class="xiding-left" @click="back">
        <img v-if="lang === 'english'" src="../assets/math/logo-w2.png" />
        <img class="xiding-left-cn" v-else src="../assets/math/logo-w3.png" />
        <!-- <img v-if="lang === 'english'" src="../assets/math/logo-w-e.png" />
        <img v-else src="../assets/math/logo-w.png" /> -->
      </div>
      <div class="xiding-right">
        <div class="xiding-right-recruit text hover" style="color: #0cd7a6">
          {{ tabbar[lang][0] }}
        </div>
        <div class="line"></div>
        <el-dropdown @command="changeLang" placement="bottom" trigger="click">
          <div class="el-dropdown-link text">
            {{ lang === 'english' ? 'EN' : '中文' }}
            <img src="../assets/math/down-arrow-banner.png" />
          </div>
          <el-dropdown-menu slot="dropdown" class="dropdown-menu">
            <el-dropdown-item
              command="english"
              class="dropdown-menu-item"
              :class="{ 'dropdown-menu-item_active': lang === 'english' }"
              >EN</el-dropdown-item
            >
            <el-dropdown-item
              command="chinese"
              class="dropdown-menu-item"
              :class="{ 'dropdown-menu-item_active': lang === 'chinese' }"
              >{{ lang === 'english' ? 'CN' : '中文' }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: 'english',
      show: false,
      rendered: false,
      tabbar: {
        english: ['Careers', 'EN'],
        chinese: ['人才招聘', '中文'],
      },
      recruits: [
        {
          english: [
            'Large Language Models, Applied ML scientist',
            '1. Lead several initiatives to contribute to vital phases of large language models at TAL, aiming to enhance next-generation TAL products, applications, and user experiences with advanced LLMs.\n2. Work together on modeling strategies to assist with the entire range of LLM tuning, including prompt engineering, instruction tuning, Reinforcement Learning from Human Feedback (RLHF), efficient parameter tuning, and fine-tuning.\n3. Enhance the efficiency of LLMs, focusing on effective teacher architectures and rapid LLM adaptation, among others.\n4. Enhance collaboration with LLM stakeholders and identify new research and product engagement opportunities.\n5. Collaborate closely with product teams to expedite the launch of their essential machine learning projects, particularly focusing on large language and multi-modal models.',
            'Location: Beijing, China/Cupertino, CA United States\nContact: wanghongzheng1@tal.com',
          ],
          chinese: [
            '大模型NLP算法工程师',
            '1、负责 NLP/多模态预训练模型研究。\n2、负责百亿、千亿级别参数量超大模型的训练及性能优化。\n3、构建超大规模的自动解题数据库，探索大型模型自动解题的潜力。\n4、跟进LLM前沿成果，关注大型模型的自动解题领域的最新进展。',
            '地点：中国-北京/美国—旧金山湾区—库比蒂诺\n投递：wanghongzheng1@tal.com',
          ],
        },
        {
          english: [
            'LLM Acceleration Engineer',
            "1. Design and develop efficient large-scale training model and inference systems to improve computational performance and accelerate model convergence speed.\n2. Optimize algorithm and model architecture to fully utilize hardware resources such as GPU and TPU.\n3. Conduct the model's performance analysis and tuning to improve training and inference speed.\n4. Track the newest achievements, propose ideas for improvement and innovation, and drive the team's technological developments.",
            'Location: Beijing, China/Cupertino, CA United States\nContact: wanghongzheng1@tal.com',
          ],
          chinese: [
            '大模型训练 & 推理加速\n工程师',
            '1、设计、开发高效的大型模型训练和推理系统，以提高计算性能和加速模型收敛速度。\n2、优化算法和模型架构，以充分利用GPU、TPU 等硬件资源。\n3、进行模型性能分析和调优，提高训练和推理速度。\n4、跟踪前沿成果，提出改进和创新的想法，推动团队的技术发展。',
            '地点：中国-北京/美国—旧金山湾区—库比蒂诺\n投递：wanghongzheng1@tal.com',
          ],
        },
        {
          english: [
            'MathGPT Product Manager',
            "1. Responsible for TAL Education's  AI strategic project, aligning with user needs, and implementing project visions.\n2. Stay updated with AI technology developments, combine it with user needs, and apply AI technology to improve user experience.\n3. Responsible for user testing of AI applications and conducting product iterations based on user feedback.\n4. Track issues during product development and work closely with the R&D team to ensure product quality.",
            'Location: Beijing, China\nContact: wanghongzheng1@tal.com',
          ],
          chinese: [
            'MathGPT产品经理',
            '1、负责好未来教育AI战略项目的产品工作，洞察用户需求，落地产品能力。\n2、紧跟AI技术发展，与用户需求结合，应用AI技术提升用户体验。\n3、负责AI技术应用的用户测试，并根据用户反馈进行产品迭代。\n4、跟踪产品开发过程中的问题，与研发团队紧密合作，确保产品质量。',
            '地点：中国-北京\n投递：wanghongzheng1@tal.com',
          ],
        },
        {
          english: [
            'UX Researcher',
            "1. Responsible for gathering market and user insights for TAL Education's AI strategic project.\n2. Proficient in operating qualitative and quantitative research methods to extract user and market insights, enabling informed decision-making in various business scenarios.\n3. Possess a strong business perspective and innovative mindset, driving innovation in product implementation and business models based on deep user insight and comprehensive business understanding.",
            'Location: Beijing, China\nContact: wanghongzheng1@tal.com',
          ],
          chinese: [
            '用户研究',
            '1、负责好未来教育AI战略项目的市场及用户洞察工作。\n2、熟练运营定性、定量研究方法，通过各种研究方法提取用户和市场洞察，支持各个业务场景决策。\n3、有较好的业务视角和创新意识，在深度洞察用户和充分理解业务的基础上，推动落地产品创新和业务模式创新。',
            '地点：中国-北京\n投递：wanghongzheng1@tal.com',
          ],
        },
        {
          english: [
            'ML Infrastructure Engineer (Golang)',
            "1. Responsible for the design and development of functional modules for TAL Education's AI business, using Go language to develop a robust and scalable server.\n2. Fulfill project's natural language processing requirements with the help of LLM model capabilities.\n3. Participate in architecture design and conduct technical proposal reviews, ensuring the control of architecture and code quality.\n4. Pay close attention to the development of the latest technologies in the AI field, as well as the preliminary research and productization of new technologies.",
            'Location: Beijing, China\nContact: wanghongzheng1@tal.com',
          ],
          chinese: [
            'AI后台Golang开发工程师',
            '1、负责好未来AI业务的功能模块设计和开发，采用Go语言开发稳健可扩展的服务端程序。\n2、借助LLM模型能力实现业务相关自然语言处理的需求。\n3、参与架构设计和技术方案评审，把控架构和代码质量。\n4、密切关注AI领域最新技术的发展，新技术的预研和产品化调研。',
            '地点：中国-北京\n投递：wanghongzheng1@tal.com',
          ],
        },
        {
          english: [
            'Senior UI Designer (AI Direction)',
            "1. Responsible for product design and creative work within TAL Education's AI direction innovation business.\n2. Participate in the preliminary planning and discussion of business and products to ensure the perfect implementation of the design.\n3. Stay updated on recent design trends and industry news, make frequent summarizations, and provide professional opinions and suggestions for our project.",
            'Location: Beijing, China\nContact: wanghongzheng1@tal.com',
          ],
          chinese: [
            '资深UI设计师（AI方向）',
            '1、负责好未来AI方向创新业务的相关产品设计创意工作；\n2、参与业务与产品的前期规划和讨论，保障设计的完美实现；\n3、关注产品与行业的设计动向，及时总结，为业务提供专业的意见及建议。',
            '地点：中国-北京\n投递：wanghongzheng1@tal.com',
          ],
        },
      ],
      footer: {
        english: 'To empower life-long growth with love and technology',
        chinese: '爱与科技助力终身成长',
      },
      ftext: {
        english: 'David Hilbert, Famous mathematician',
        chinese: '戴维·希尔伯特，著名数学家',
      },
      fbigtext: {
        english: 'Wir müssen wissen,\nWir werden wissen.',
        chinese: '我们必须知道，\n我们必将知道。',
      },
    }
  },
  // created() {
  //   document.title = "九章大模型(MathGPT)";
  // },
  mounted() {
    const query = this.$route.query
    this.lang = query.lang || 'chinese'

    window.addEventListener('scroll', () => {
      let scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 0) this.show = true
      else this.show = false
    })
  },
  methods: {
    changeLang(lang) {
      this.lang = lang
    },
    back() {
      this.$router.push({ name: 'Math', query: { lang: this.lang } })
    },
    toBeian() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    },
    toBeian2() {
      window.open(
        'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802042225'
      )
    },
  },
}
</script>

<style lang="less" scoped>
.home {
  overflow-x: hidden;
  .header {
    width: 100vw;
    padding: calc(11 / 375 * 100vw) calc(22 / 375 * 100vw) 0;
    box-sizing: border-box;
    background: linear-gradient(124.5deg, #d9fe9a -36.5%, #60fdc6 54.13%);
    &-top {
      height: calc(52 / 375 * 100vw);
    }
    // &-top {
    //   display: flex;
    //   justify-content: space-between;
    //   &-left {
    //     display: flex;
    //     align-items: center;
    //     cursor: pointer;
    //     img {
    //       width: calc(125 / 375 * 100vw);
    //       height: calc(30 / 375 * 100vw);
    //       object-fit: cover;
    //       vertical-align: top;
    //     }
    //   }
    //   &-right {
    //     display: flex;
    //     align-items: center;
    //     .line {
    //       width: calc(1 / 375 * 100vw);
    //       height: calc(10 / 375 * 100vw);
    //       background: #000;
    //       margin: 0 74px;
    //       border-radius: 1px;
    //     }
    //     .text {
    //       font-family: "PingFang SC";
    //       font-weight: 400;
    //       font-size: calc(14 / 375 * 100vw);
    //       color: #000;
    //     }
    //   }
    // }
    &-bottom {
      padding: 0 0 calc(25 / 375 * 100vw) 0;
      box-sizing: border-box;
      &-title {
        margin: calc(40 / 375 * 100vw) 0 0 0;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: calc(22 / 375 * 100vw);
        line-height: calc(30 / 375 * 100vw);
      }
      &-desc {
        margin: calc(16 / 375 * 100vw) 0 0 0;
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: calc(14 / 375 * 100vw);
        line-height: calc(22 / 375 * 100vw);
        white-space: pre-wrap;
      }
      &-contact {
        margin: calc(22 / 375 * 100vw) 0 0 0;
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: calc(14 / 375 * 100vw);
        line-height: calc(22 / 375 * 100vw);
        white-space: pre-wrap;
      }
    }
  }

  .footer {
    width: 100vw;
    padding: calc(20 / 375 * 100vw) 0 calc(12 / 375 * 100vw);
    background: #000;
    &-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      &-logo {
        img {
          width: calc(258 / 375 * 100vw);
          //height: calc(30 / 375 * 100vw);
          object-fit: cover;
          vertical-align: top;
        }
      }
      &-text {
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: calc(20 / 375 * 100vw);
        transform: scale(0.5);
        color: #fff;
        text-align: center;
        white-space: nowrap;
      }
    }
  }

  .xiding {
    width: 100vw;
    padding: calc(11 / 375 * 100vw) calc(22 / 375 * 100vw);
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    display: flex;
    justify-content: space-between;
    &-left {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: calc(125 / 375 * 100vw);
        //height: calc(30 / 375 * 100vw);
        object-fit: cover;
        vertical-align: top;
      }
      &-cn {
        width: calc(180 / 375 * 100vw) !important;
      }
    }
    &-right {
      display: flex;
      align-items: center;
      .line {
        width: calc(1 / 375 * 100vw);
        height: calc(10 / 375 * 100vw);
        background: #fff;
        margin: 0 74px;
        border-radius: 1px;
      }
      .text {
        font-family: 'PingFang SC';
        font-weight: 400;
        font-size: calc(14 / 375 * 100vw);
        color: #fff;
      }
    }
  }
}
// 下拉框
.el-dropdown-link {
  display: flex;
  align-items: center;
  img {
    width: calc(10 / 375 * 100vw);
    height: calc(10 / 375 * 100vw);
    margin: 0 0 0 calc(5 / 375 * 100vw);
    object-fit: cover;
    vertical-align: top;
  }
}
// 下拉菜单
.dropdown-menu {
  padding: calc(21 / 375 * 100vw) calc(21 / 375 * 100vw) 0 !important;
  border: 1px solid #000;
  border-radius: calc(10 / 375 * 100vw);
  background: #000;
  &-item {
    padding: 0 0 calc(21 / 375 * 100vw) 0 !important;
    font-family: 'PingFang SC' !important;
    font-weight: 400 !important;
    font-size: calc(14 / 375 * 100vw) !important;
    line-height: 1;
    color: #fff;
    text-align: center;
    &:hover {
      color: #60fdc7 !important;
      background-color: #000 !important;
    }
  }
  &-item_active {
    color: #60fdc7 !important;
  }
  /deep/ .popper__arrow {
    display: none !important;
  }
}
</style>
